
import withRoot from '../common/withRoot';
import Header from './Header'
import Categories from './Categories'

function Home() {
  return (
    <div>
      <Header/>
      <Categories/>    
    </div>
  );
}
export default withRoot(Home);