import * as React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Container } from '@material-ui/core';
import BusinessCard from './BusinessCard'
import Typography from '../common/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  heading: {
    marginBottom: 30,
  },
  list: {
    backgroundColor: '#F8F8F8' ,
    padding: 15,
  },
});


export default function BusinessList(props) {
  const classes = useStyles();
  const businesses = props.businesses
  return (
    <Container >

      <Box sx={{ width: '100%' }} className={classes.list}>
        <Grid container spacing={2} > 
            {businesses.map((business, i) => (
                <Grid item xs={10} sm={5} md={3} key={i}>
                    <BusinessCard business={business}/>
                </Grid>
            ))}
        </Grid>
      </Box>

    </Container>
    
  );
}
