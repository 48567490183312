import React, {useState,useEffect} from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Container } from '@material-ui/core';
import OutletCard from './OutletCard'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  list: {
    backgroundColor: '#F8F8F8' ,
    padding: 15,
  },
});

export default function Outlets(props) {
  const classes = useStyles();
  const [outlets, setOutlets] = useState([]);

  useEffect (()=>{
    setOutlets(props.outlets);
  },[props.outlets]);

  return (
    <Container >
      <Box sx={{ width: '100%' }} className={classes.list}>
        <Grid container spacing={2} > 
            {outlets.map((outlet, i) => (
                <Grid item xs={10} sm={5} md={3} key={i} >
                    <OutletCard outlet={outlet}/>
                </Grid>
            ))}
        </Grid>
      </Box>
    </Container>
    
  );
}
