import React,{useState, useEffect,useCallback, useRef } from "react";
import {makeStyles} from "@material-ui/core";
import logo from './shopkae_logo.png';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import axios from '../../services/Axios';
import jwt_decode from 'jwt-decode';
import ReCAPTCHA from "react-google-recaptcha";
 

const useStyles = makeStyles(() => ({
  logo_style: {
    marginTop: 8,
    maxWidth: 150,
  },
}));

export default function Topnav() {

  const { logo_style } = useStyles();
  
  const [credentials, setCredentials] = useState({});

  const [user, setUser] = useState({});

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const recaptchaRef = useRef();
  // const RECAPTCHA_SIDE_KEY = process.env.RECAPTCHA_SIDE_KEY


  useEffect (()=>{
    if (localStorage.getItem('accessToken') !== null)  {
      setUser(jwt_decode(localStorage.getItem('accessToken')));
      setIsLoggedIn(true)    
    } 
  }, []);
  
  const handleLogin = useCallback(  async()=> {
    // console.log("Credentials:", JSON.stringify(credentials));
    let response = await axios.post("/users/authenticate", credentials)
    setCredentials({
      email: "",
      password: "",
      captcha: "",
    })
    localStorage.setItem('accessToken', response.data.accessToken);
    localStorage.setItem('refreshToken', response.data.refreshToken);
    setUser(jwt_decode(response.data.accessToken));
    setIsLoggedIn(true) 
    setShow(false);
    
  },[credentials]);

  const reCAPTCHAHandler = (value) => {
    setCredentials(credentials => ({...credentials, captcha: value}))
    // console.log("Captcha value:", value);
    // console.log("Credentials:", JSON.stringify(credentials));
  }

  const handleLogout = useCallback(  async()=>{
    await axios.post("/users/logout");
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    setIsLoggedIn(false)
    setUser({});
  },[]);
  
  
  return (
    <>
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top">
    <Container fluid>
    <Navbar.Brand href="/home">
        <img src={`${logo}`} alt="logo" className={logo_style}/>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="me-auto">
        {/* <Nav.Link href="/products">Products</Nav.Link> */}
        <Nav.Link href="/businesses">Businesses</Nav.Link>
      </Nav>
      <Nav>
          {isLoggedIn ? (
              <NavDropdown title={user.firstName} id="collasible-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Profile</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item  onClick={handleLogout}  href="#action/3.2">
                  logout
                </NavDropdown.Item>
            </NavDropdown>
          ) : (
            <Button variant="outline-primary" onClick={handleShow}>
              Login
            </Button>
          )}
      </Nav>
    </Navbar.Collapse>
    </Container>
  </Navbar>


  <Modal show={show} onHide={handleClose}>

  <Modal.Header closeButton>
    <Modal.Title>Login</Modal.Title>
  </Modal.Header>

  <Modal.Body>
    <Form>   
      <Form.Group className="mb-3"  controlId="loginForm.Email">
        <Form.Label>Email</Form.Label>
        <Form.Control
          variant="primary"
          type="email"
          placeholder="name@example.com"
          onChange={e => setCredentials(credentials => ({...credentials, email: e.target.value}))}
          autoFocus
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="loginForm.password">
        <Form.Label>Password</Form.Label>
        <Form.Control type="password" 
          onChange={e => setCredentials(credentials => ({...credentials, password: e.target.value}))}/>
      </Form.Group>

      <Form.Group className="mb-3" controlId="loginForm.password">
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_RECAPTCHA_SIDE_KEY} //"6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
          onChange={reCAPTCHAHandler}
        />
      </Form.Group>

    </Form>


  </Modal.Body>

  <Modal.Footer>
    <Button variant="secondary" onClick={handleClose}>
      Close
    </Button>
    <Button variant="primary" onClick={handleLogin}>
      Login
    </Button>
  </Modal.Footer>

  </Modal>
 </>   
  );
}