import React, {useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardContent:{
    textAlign: 'left',
  },
});

export default function OutletCard(props) {
  
  const classes = useStyles();
  const [outlet, setOutlet] = useState({});
  
  useEffect (()=>{
    setOutlet(props.outlet);
  },[props.outlet]);
  

  return (
    <Card className={classes.root}>
      <CardContent className={classes.cardContent}>
        <Typography variant="body2" component="p">
          {outlet.type}
        </Typography>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
            {outlet.name}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {outlet.phone && outlet.phone.length > 0 && outlet.phone[0]}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {outlet.email && outlet.email.length > 0 && outlet.email[0]}
        </Typography>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          {outlet.address && outlet.address.city}
        </Typography>
      </CardContent>
      {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
  );
}
