import axios from "axios"
import jwt_decode from 'jwt-decode';

const instance =  axios.create({
    baseURL : process.env.REACT_APP_BASE_URL
});
    
const refreshToken = async () => {
    try {
      const response = await axios.post(`
        ${process.env.REACT_APP_BASE_URL}/users/refresh`, 
        { refreshToken: localStorage.getItem('refreshToken') },
        {headers: {refresh: localStorage.getItem('refreshToken')}});

      localStorage.setItem('accessToken', response.data.accessToken);
      localStorage.setItem('refreshToken', response.data.refreshToken);
      return response.data;
    } catch (err) {
      console.log(err);
    }
  };

instance.interceptors.request.use(
    async (config) => {
      if (localStorage.getItem('accessToken') !== null  && localStorage.getItem('refreshToken') !== null){
        let currentDate = new Date();
        const decodedToken = jwt_decode(localStorage.getItem('accessToken'));
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            const data = await refreshToken();
            config.headers["authorization"] = "Bearer " + data.accessToken;
            config.headers["refresh"] =  data.refreshToken;
        }
        else{
            config.headers["authorization"] = "Bearer " + localStorage.getItem('accessToken');
            config.headers["refresh"] =  localStorage.getItem('refreshToken');
        }

      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

export default instance;