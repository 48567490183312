import React, {useState, useEffect, useCallback} from 'react';
import withRoot from '../common/withRoot';
import BusinessList from './BusinessList'
import { makeStyles } from '@material-ui/core/styles';
import axios from '../../services/Axios';

const useStyles = makeStyles({
  root: {
    marginTop: 90,
  },
});

function Businesses() {
  const classes = useStyles();
  const [businesses, setBusinesses] = useState([]);
  
  const getAllBussinesses = useCallback(  async()=> {
    let response = await axios.get("/business")
    setBusinesses(response.data)  
  },[]);

  useEffect (()=>{
    getAllBussinesses()
  }, [getAllBussinesses]);

  return (
    <div className={classes.root} >
     <BusinessList title="Businesses" businesses={businesses}/>
    </div>
  );
}
export default withRoot(Businesses);