import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Link , useRouteMatch}from 'react-router-dom'

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  image: {
    height: 240,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',  
  },
  link: {
    textDecoration:"none",
  },
});

export default function BusinessCard(props) {
  const classes = useStyles();
  const business = props.business
  const routeMatch = useRouteMatch();
  return (
    <Card className={classes.root}>
      <CardActionArea>
        <Link to={`${routeMatch.path}/${business.name}`} className={classes.link}>     
            <div 
            className={classes.image} 
            style={{ backgroundImage: `url(${business.logo})`}}/>
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                {business.name}  
            </Typography>
            {/* <Typography gutterBottom variant="h5" component="h2">
                {business.name}
            </Typography> */}

            </CardContent>
        </Link>
      </CardActionArea>
      {/* <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions> */}
    </Card>
  );
}