import { Envelope, Instagram, Facebook } from 'react-bootstrap-icons';
import './footer.css';


const Footer = () => <footer className="footer">
    <div className= "icons">
        <span> <a href="https://instagram.com/_shopkae"><Instagram/></a> </span>
        <span> <a href="https://facebook.com/shopkae19"><Facebook/></a></span>
    </div>

    <div>
        <span> <Envelope/></span> 
        <span> info.shopkae@gmail.com </span>
    </div>

    <div>
      <a href="https://shopkae.co.za">Shopkae</a>
      <span>&copy; 2022.</span>
    </div>

</footer>

export default Footer

