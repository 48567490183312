import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom'
import './App.css';
import TpoNav from './pages/common/TopNav'
import Footer from './pages/common/Footer'
import NotFound from './pages/common/NotFound'
import ReactGA from 'react-ga';
import Breadcrumbs from './pages/common/Breadcrumbs';
import routes from './pages/common/Routes';

ReactGA.initialize('UA-158854733-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const App = () => (
    <Router>
       {/* w-screen h-screen flex justify-center p-8 */}
      <div className="App">
        <TpoNav/>
        <Switch>
        <Route path="/" exact> 
           <Redirect to='/home'></Redirect>
        </Route>
        {routes.map(({ path, name, Component }, key) => (
          <Route
              exact
              path={path}
              key={key}
              render={props => {
                const crumbs = routes
                  // Get all routes that contain the current one.
                  .filter(({ path }) => props.match.path.includes(path))
                  // Swap out any dynamic routes with their param values.
                  // E.g. "/pizza/:pizzaId" will become "/pizza/1"
                  .map(({ path, ...rest }) => ({
                    path: Object.keys(props.match.params).length
                      ? Object.keys(props.match.params).reduce(
                          (path, param) =>
                            path.replace(`:${param}`, props.match.params[param]),
                          path
                        )
                      : path,
                    ...rest
                  }));
  
                // console.log(`Generated crumbs for ${props.match.path}`);
                // crumbs.map(({ name, path }) => console.log({ name, path }));
  
                return (
                  // className="p-8"
                  <div >
                    <Breadcrumbs crumbs={crumbs} />
                    <Component {...props} />
                  </div>
                );

              }}
            />
          ))}
          <Route path="*" > 
              <NotFound/>
          </Route>
        </Switch>
        <Footer/>
      </div>
    </Router>
  );

export default App;




// function App() {

//   return (
//     <div className="App w-screen h-screen flex justify-center p-8">
//       <TpoNav/>      
//       <main>
//         <Switch>
//           <Route path="/" exact> 
//           <Redirect to='/home'></Redirect>
//           </Route>
//           <Route path="/home" > 
//             <Home/>
//           </Route>  
//           <Route path="/products" exact> 
//             <Products/>
//           </Route>  
//           <Route path="/products/:name" > 
//             <ProductDetails/>
//           </Route>  
//           <Route path="/businesses" exact > 
//             <Businessess/>
//           </Route> 
//           <Route path="/businesses/:name" > 
//             <BusinessDetails/>
//           </Route>
//           <Route path="*" > 
//             <NotFound/>
//           </Route>
//         </Switch> 
//       </main>
//       <Footer/>
//     </div>
//   );
// }



