import withRoot from './withRoot';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    marginTop: 90,
  },
});

function NotFound() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
     <h1>Page Not Found 404</h1>
    </div>
  );
}
export default withRoot(NotFound);