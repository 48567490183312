import React, {useState, useEffect} from 'react';
import ImageGallery from 'react-image-gallery';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    root: {
    //   marginTop: 90,
    },
    image: {
      margin: 'auto',
      maxHeight: 600,
      maxWidth: 600,
      // backgroundPosition: 'center',
      // backgroundRepeat: 'no-repeat',
      // // backgroundSize: 'contain',
      // fontSize: 'contain',
      // width: 100%;
      // height: auto;
      // max-height: 80vh;
      // object-fit: cover;
      overflow: "hidden",
      objectPosition: "center",
      objectFit: "contain", 
      // object-position: center center;
      "@media (max-width: 900px)": {
        thumbnailPosition: "bottom",
      },
    },
    originalImage: {
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',  
      },
  });


function Gallery(props) {
    const classes = useStyles();
    const [images, setImages] = useState([]);

    useEffect (()=>{
        setImages(props.images)
      }, [props.images]);

    return (
        <Container className={classes.root}>         
            <ImageGallery showPlayButton={false} onErrorImageURL="Product" thumbnailPosition="left" items={images}
                additionalClass={classes.image}     
            />
        </Container>

    );
}

export default Gallery;
