import React, {useState,useEffect}from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Link}from 'react-router-dom'

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  image: {
    height: 240,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',  
  },
  link: {
    textDecoration:"none",
  },
});

export default function ProductCard(props) {
  const classes = useStyles();
  const [product, setProduct] = useState({});
  
  useEffect (()=>{
    setProduct(props.product);
  },[props.product]);

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <Link to={`/products/${product.name}`} className={classes.link} >
          <div 
            className={classes.image} 
            style={{ backgroundImage: `url(${product.images && product.images.length > 0 && product.images[0]})`}}/>
          <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
              {product.name} - {product.model}
            </Typography>
            {/* <Typography gutterBottom variant="h5" component="h2">
              {product.name}- {product.model}
            </Typography> */}

          </CardContent>
        </Link>
      </CardActionArea>
      {/* <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
      </CardActions> */}
    </Card>
  );
}