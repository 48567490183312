import React, {useState, useEffect,useCallback} from 'react';
import withRoot from '../common/withRoot';
import ProductList from './ProductList'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '../common/Typography';
import axios from '../../services/Axios';

const useStyles = makeStyles({
  root: {
    marginTop: 90,
  },
  heading: {
    marginBottom: 30,
  },
});

function Products() {
  const classes = useStyles();
  const [products, setProducts] = useState([]);
  const getAllProducts = useCallback(async ()=>{
    let response = await axios.get("/product")
    setProducts(response.data)  
  },[]) 
  
  useEffect (()=>{
    getAllProducts()
  }, [getAllProducts]);

  return (
    <div className={classes.root}>
     <ProductList products={products} />
    </div>
  );
}
export default withRoot(Products);