import React, {useState, useEffect , useCallback} from 'react';
import {useParams} from 'react-router-dom'
import withRoot from '../common/withRoot';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import BusinesProfile from './BusinessProfile'
import axios from '../../services/Axios';

const useStyles = makeStyles({
  root: {
    marginTop: 90,
  },
});


function BusinessDetails() {
    const classes = useStyles();
    const params = useParams();
    const [business, setBusiness] = useState({});
    

    const getBussinessByName = useCallback( async () =>{
      let url = `/business/name/${params.name}` ;
      const response = await axios.get(url);
      setBusiness(response.data); 
    }, [params.name]);
    

    useEffect (()=>{
      getBussinessByName()
    },[getBussinessByName]);

  
    return (
        <Container className={classes.root}>
        <BusinesProfile business={business}/>
        </Container>
    );
}
export default withRoot(BusinessDetails);