import React, {useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tabs from './Tabs'


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  img: {
    // margin: 'auto',
    display: 'block',
    width: 300,
    height: 300,
    border: 0.5,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(240, 240, 245)',
    // borderRadius: 100,

  },
  textContent: {
    textAlign: "left",
    padding: 10,
  },
}));

function BusinesProfile(props) {
  const classes = useStyles();
  const [business, setBusiness] = useState({}) ;

  useEffect (()=>{
    setBusiness(props.business);
  },[props.business]);

  return (
    <div className={classes.root}>

        <Grid container>
            <Grid item>
                <img className={classes.img} alt="logo" src={business.logo}/>
            </Grid>
            <Grid item className={classes.textContent}>
                <Typography gutterBottom variant="subtitle1">
                  {business.name}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {business.industry}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                {business.website}
                </Typography>

            </Grid>
        </Grid>
        <Tabs business={business} ></Tabs>
 
    </div>
  );
}

export default BusinesProfile
