import React from "react";
import { Link } from "react-router-dom";
import './breadcrumbs.css';

const Breadcrumbs = ({ crumbs }) => {
  // Don't render a home.
  if (crumbs.length <= 1 && crumbs[0].path === "/home") {
    return null;
  }
  
  return (
    <div className="breadcrumbs">
      {/* Link back to any previous steps of the breadcrumb. */}
      {crumbs.map(({ name, path }, key) =>
        key + 1 === crumbs.length ? (
          <span key={key} className="breadcrumbstab" >
            / { path.split('/').length > 2 ?path.split('/').pop():name}
          </span>
        ) : (
            <Link key={key} to={path} className="breadcrumbslink" >
              /{name}
            </Link>
          )
      )}
    </div>
  );
};

export default Breadcrumbs;