import LightGallery from 'lightgallery/react';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// // If you want you can use SCSS instead of css
// import 'lightgallery/scss/lightgallery.scss';
// import 'lightgallery/scss/lg-zoom.scss';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';

import React , {useEffect, useCallback, useRef} from 'react';
import { Container } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    box: {
      backgroundColor: '#F8F8F8' ,
      padding: 10,
    },
    list: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '0 4px',
    },
    galleryitem: {
        margin: 5,
    }
  });


function Gallery(props) {

    const classes = useStyles();
    const lightGallery = useRef(null);

    const onInit = useCallback((detail) => {
        if (detail) {
            lightGallery.current = detail.instance;
        }
    }, []);


    const getItems = useCallback(() => {
        return props.images.map((image, index) => {
            return (
                <div
                    key={index}
                    className={classes.galleryitem} 
                    data-src={image}
                >
                    <img style={{ maxWidth: '200px'}} className="img-responsive" alt="img" src={image} />
                </div>
            );
        });
    }, [classes.galleryitem, props.images]);


    useEffect (()=>{
        lightGallery.current.refresh();
      },[]);


    return (
        
        <Container >
            <Box sx={{ width: '100%'}} className={classes.box}>

                <LightGallery
                    plugins={[lgVideo,lgZoom, lgThumbnail]}
                    elementClassNames={classes.list}
                    onInit={onInit}
                >
                    {getItems()}
                </LightGallery>

            </Box>
        </Container>
       
    );
  }


export default Gallery;