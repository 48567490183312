import React, {useState,useEffect} from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Container } from '@material-ui/core';
import ProductCard from './ProductCard'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  list: {
    backgroundColor: '#F8F8F8' ,
    padding: 15,
  },
});


export default function ProductList(props) {
  const classes = useStyles();
  const [products, setProducts] = useState([]);


  useEffect (()=>{
    setProducts(props.products);
  },[props.products]);

  return (
    <Container >
 
      <Box sx={{ width: '100%' }} className={classes.list}>
        <Grid container spacing={2} > 
            {products.map((product, i) => (
                <Grid item xs={10} sm={5} md={3} key={i} >
                    <ProductCard product={product}/>
                </Grid>
            ))}
        </Grid>
      </Box>

    </Container>
    
  );
}
