import React , {useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
// import ProductList from '../product/ProductList'
import Outlets from './Outlets'
import Box from '@material-ui/core/Box';
import Gallery from '../common/Gallery'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  description: {
    textAlign: "left",
  }

}));

function  ScrollableTabsButtonAuto(props) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [business, setBusiness] = useState({}) ;
  const [outlets, setOutlets] = useState([]);
  const [images, setImages] = useState([]);
  // const [products, setProducts] = useState([]);
  
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect (()=>{
    setBusiness(props.business);
    // setProducts(business.products);
    setOutlets(business.outlets)
    setImages(business.images)
  },[props.business,business.products,business.outlets, business.images]);

  return (
    <div className={classes.root}>
        <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
        >
            <Tab label="Description" {...a11yProps(0)} />
            <Tab label="Images" {...a11yProps(1)} />
            <Tab label="Outlets" {...a11yProps(2)} />
            {/* <Tab label="Products" {...a11yProps(3)} /> */}
        </Tabs>
    

        <TabPanel value={value} index={0} className={classes.description}>
          <Typography>{business.description}</Typography>
        </TabPanel>
        <TabPanel value={value} index={1}>
            {images && images.length > 0 ? <Gallery images={images}/> : <Typography>No Images</Typography> }
        </TabPanel>
        <TabPanel value={value} index={2}>
            {outlets && outlets.length > 0 ? <Outlets outlets={outlets} /> : <Typography>No Outlets</Typography> }
        </TabPanel>
        {/* <TabPanel value={value} index={3}>
          {products && products.length > 0 ? <ProductList products={products} /> : <Typography>No products sold on this platform</Typography> }     
        </TabPanel> */}
    </div>
  );
}

export default React.memo(ScrollableTabsButtonAuto)
