import Home from '../home/Home'
import Products from '../product/Products'
import ProductDetails from '../product/ProductDetails'
import Businessess from '../business/Businessess'
import BusinessDetails from '../business/BusinessDetails'

const routes = [
  { path: "/home", name: "Home", Component: Home },
  { path: "/businesses", name: "Businesses", Component: Businessess},
  { path: "/products", name: "Products", Component: Products },
  { path: "/businesses/:name", name: "Business Details", Component: BusinessDetails },
  { path: "/products/:name", name: "Product Details", Component: ProductDetails }
];

export default routes
