import React, {useState, useEffect, useCallback} from 'react';
import {useParams} from 'react-router-dom'
import withRoot from '../common/withRoot';
import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Gallery from './Gallery'
import axios from '../../services/Axios';
import {Link}from 'react-router-dom'

const useStyles = makeStyles({
  root: {
    marginTop: 90,
    marginBottom: 50,
    "@media (max-width: 800px)" : {
      marginBottom: 20,
     },
  },
  textContent: {
    textAlign: "left",
    padding: 10,
  },
  link: {
    textDecoration:"none",
    color:"textSecondary",

  },
  originalImage: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',  
  },
});

function ProductDetails() {
  const classes = useStyles();
  const params = useParams();
  const [product, setProduct] = useState({});
  const [images, setImages] = useState([]);
  

   const  getProductByName = useCallback(async ()=>{
    let url = `/product/name/${params.name}` ;
    const response = await axios.get(url);
    setProduct(response.data); 
    setImages(response.data.images.map((str, index) => (
      { original: str,
        thumbnail:str, 
        id: index + 1, 
        originalClass:classes.originalImage,
       }))); 
  }, [params.name,classes.originalImage]);

  useEffect (()=>{
    getProductByName()
  }, [getProductByName]);

  return (
    <Container className={classes.root}>
     
        <Grid container>

            <Grid item >
                <Gallery  images={images}/>
            </Grid>

            <Grid item className={classes.textContent}>
                <Typography gutterBottom variant="subtitle1">
                    {product.name} - {product.model}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Category: {product.category}
                </Typography>
                <Typography variant="body2">
                  Business: <Link to={`/businesses/${product.businessName}`} className={classes.link} >
                    {product.businessName}
                  </Link>
                </Typography>
                <hr/>
                <Typography variant="body2" gutterBottom>
                  {product.description}
                </Typography>
            </Grid>
        </Grid>
     
    </Container>
  );
}

export default withRoot(ProductDetails);